import request from '@/utils/request'
import { stringify } from 'qs'

export function result(params) {
  return request({
    url: '/judges/result',
    method: 'post',
    data: params,
  })
}

export function save(params) {
  return request({
    url: '/judges',
    method: 'post',
    data: params,
  })
}

export function list(params) {
  return request({
    url: `/judges?${stringify(params)}`,
    method: 'get',
  })
}

export function myResult(params) {
  return request({
    url: `/judges/my-all-result?${stringify(params)}`,
    method: 'get',
  })
}

export function del(id) {
  return request({
    url: `/judges/${id}`,
    method: 'delete',
  })
}
