<template>
  <div class="container">
    <nav-bar title="我的建筑评判结果"></nav-bar>
    <md-field>
      <md-cell-item
        v-for="item in list"
        :key="item.id"
        :title="item.body.name"
        :brief="item.body.type + ' / ' + item.created_at"
        arrow
        @click="handleClick(item)"
      />
    </md-field>
    <md-action-sheet v-model="visible" title="选择操作项" :default-index="0" :options="options" @selected="handleSelect"></md-action-sheet>
  </div>
</template>

<script>
import * as judgeApi from '@/api/judge'

export default {
  data() {
    return {
      list: [],
      visible: false,
      options: [
        {
          label: '查看',
          value: 0,
        },
        {
          label: '删除',
          value: 1,
        },
      ],
      data: {},
      id: undefined,
    }
  },
  created() {
    judgeApi.myResult().then(res => (this.list = res.data))
  },
  methods: {
    handleClick(item) {
      this.visible = true
      this.data = item.body
      this.id = item.id
    },
    handleSelect(val) {
      if (val.value === 0) {
        this.$ls.set('judge', JSON.stringify(this.data))

        this.$router.push({ name: 'judge-result', query: { is_view: 'true' } })
      } else {
        this.$dialog.confirm({
          title: '确认',
          content: '确认删除该评判结果吗？',
          confirmText: '确定',
          onConfirm: () =>
            judgeApi.del(this.id).then(() => {
              this.list = this.list.filter(item => item.id !== this.id)
              this.$toast.info('删除成功')
            }),
        })
      }
    },
  },
}
</script>
<style lang="stylus" scoped></style>
